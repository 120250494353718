import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop(props) {
  const { pathname } = useLocation();

  if (localStorage.devMode !== 'true')
  // debugger;
    if (props.scroll || props.scroll === undefined) {
      window.scrollTo(0, 0);
      if (props.setScroll) {
        props.setScroll(false);
      }
    }

  // useEffect(() => {
  //   setTimeout(() => {
  //     window.scrollTo(0, 0);
  //   }, 100);
  // }, [pathname]);

  return null;
}