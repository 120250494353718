import "./Faq.css";
import QuizIcon from '@mui/icons-material/Quiz';

const Faq = () => {
  return (
    <>
      <section className="faq">
        {/* <div className="faq-icon"><QuizIcon fontSize="large"/></div> */}
        <div className="faq-title">
          Frequently <br /> Asked <br /> Questions
        </div>
      <div className="faq_questions">
        <div className="faq_question">I am new to counselling and therapy, what can I expect?</div>
        <br/>
        <div className="faq-answer">Therapy is a safe and non-judgemental space to focus on and explore any challenges or issues in your life. Each session is tailored to your specific needs. Your therapist will support you to address any issues you may wish to tackle, talking you through your thoughts, emotions and behaviours. A free 15 minute online or telephone consultation is available to provide more information and answer any questions you have on the counselling and therapy process. This consultation is non-obligatory, which means you <b>are not</b> required to commit to signing up for therapy once you have completed consultation.</div>
        <br/>
        <br/>
        <div className="faq_question">How long is a therapy session?</div>
        <br/>
        <div className="faq-answer">Each session lasts for 1 hour.</div>
        <br/>
        <br/>
        <div className="faq_question">How many sessions do I need to do?</div>
        <br/>
        <div className="faq-answer">This is entirely up to you and will also be dependant on the issues you wish to tackle. Your therapist can help you figure this out. With this in mind, at least 6 sessions are recommended at a minimum.</div>
        <br/>
        <br/>
        <div className="faq_question">What type of things can therapy help with?</div>
        <br/>
        <div className="faq-answer">There are many areas in which therapy can support you. Some may seek help with anxiety, depression, suicidal thoughts, bereavement, relationship issues amongst many things. These are challenges which all of us will face at some time in our life and getting support with these issues can help share the burden and offer relief. Other times you may wish to seek therapy to help with self-esteem, to build confidence or to support you with your goals (professional, personal, relationship) and in big times of change such as parenthood, career change, retirement to name a few. </div>
        <br/>
        <br/>
        <div className="faq_question">Do I need to do therapy in person?</div>
        <br/>
        <div className="faq-answer">Some clients find working face to face with a therapist more beneficial while others prefer to work by video conference online. Again, this depends on your preference and your lifestyle. You may find it difficult to get to face to face sessions and online helps with your scheduling and reduces the time needed for travel to and from etc. Or you may not have access to transport and find online suits your needs. Others prefer the privacy of attending therapy outside of their own home and the communication style of meeting in person. Either way, both can be accommodated.</div>
        <br/>
        <br/>
        <div className="faq_question">How much do the sessions cost?</div>
        <br/>
        <div className="faq-answer">Each session costs 65 euro, however a reduced fee is available if you are a student, pensioner or currently unwaged. This can be discussed during the free consultation. </div>
        <br/>
        <br/>
        <div className="faq_question">How often do I need to have sessions?</div>
        <br/>
        <div className="faq-answer">Again, this can be agreed between you and the therapist at consultation but usually it is once a week or once a fortnight. This frequency is to ensure an appropriate momentum to your sessions and progress. </div>
        <br/>
        <br/>
        <div className="faq_question">What if I have the free consultation and then don't want to access therapy?</div>
        <br/>
        <div className="faq-answer">No problem! The consultation is there to explore whether you feel therapy is a good fit at this time. It is also there for you to consider if I am the right therapist for you. It is very important that you feel at ease with your therapist and for some reason you may decide that I am not right for you. That's a completely normal part of finding therapy that works for you.</div>
        <br/>
        <br/>
      </div>
       </section>
    </>
  );
};

export default Faq;
