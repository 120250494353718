import "./Testimonials.css";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import AddTaskIcon from "@mui/icons-material/AddTask";
import PersonPinIcon from '@mui/icons-material/PersonPin';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { useNavigate } from "react-router-dom";

const Testimonials = () => {
  const navigate = useNavigate();
  return (
    <section className="testimonials">
      <div className="testimonial testimonial-1" onClick={() => {
          navigate("/contactBook");
        }}>
        <div className="testimonial-icon">
          <EditCalendarIcon />
        </div>
        <div className="testimonial-text">Contact/Book</div>
      </div>
      <div className="testimonial testimonial-2"  onClick={() => {
          navigate("/servicesCost");
        }}>
        <div className="testimonial-icon">
          <AddTaskIcon />
        </div>
        <div className="testimonial-text">Services/Cost</div>
      </div>
      <div className="testimonial testimonial-3" onClick={() => {
          navigate("/aboutTherapist");
        }}>
        <div className="testimonial-icon">
          <PersonPinIcon />
        </div>
        <div className="testimonial-text">About your Therapist</div>
      </div>
      <div className="testimonial testimonial-4" onClick={() => {
          navigate("/faq");
        }}>
        <div className="testimonial-icon">
          <ContactSupportIcon />
        </div>
        <div className="testimonial-text">FAQ</div>
      </div>
      {/* <div className="testimonial testimonial-1">
        All of us can find life challenging at times and therapy is there to
        provide extra support when you need it. Whether you are struggling with
        a specific issue such as anxiety, burnout, depression, suicide or
        bereavement or if you are feeling generally overwhelmed day to day,
        therapy can provide knowledge and pathways to self-empowerment and
        healing, which can help relieve these issues. A free 15-minute,
        non-obligation and confidential consultation is provided to discuss all
        your queries and concerns about therapy and how it may be of help. <br />
        Book here.
      </div>
      <div className="testimonial testimonial-2">
        Proin sed libero enim sed faucibus turpis. At imperdiet dui accumsan sit
        amet nulla facilisi morbi tempus. Ut sem nulla pharetra diam sit amet
        nisl. Enim nunc faucibus a pellentesque sit amet. Sed turpis tincidunt
        id aliquet risus feugiat in ante metus.
      </div>
      <div className="testimonial testimonial-3">
        Proin sed libero enim sed faucibus turpis. At imperdiet dui accumsan sit
        amet nulla facilisi morbi tempus. Ut sem nulla pharetra diam sit amet
        nisl. Enim nunc faucibus a pellentesque sit amet. Sed turpis tincidunt
        id aliquet risus feugiat in ante metus.
      </div>
      <div className="testimonial testimonial-4">
        Proin sed libero enim sed faucibus turpis. At imperdiet dui accumsan sit
        amet nulla facilisi morbi tempus. Ut sem nulla pharetra diam sit amet
        nisl. Enim nunc faucibus a pellentesque sit amet. Sed turpis tincidunt
        id aliquet risus feugiat in ante metus.
      </div> */}
    </section>
  );
};

export default Testimonials;
