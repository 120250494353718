import "./App.css";
import Footer from "./components/Footer/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import CounsellingAndPsychotherapy from "./pages/CounsellingAndPsychotherapy/CounsellingAndPsychotherapy";
import AboutYourTherapist from "./pages/AboutYourTherapist/AboutYourTherapist";
import ContactBook from "./pages/ContactBook/ContactBook";
import ServicesCost from "./pages/ServicesCost/ServicesCost";
import Faq from "./pages/FAQ/Faq";

function App() {
  localStorage.headerFirstLoad = 'true';
  localStorage.counsellingAndPsychotherapyFirstLoad = 'true';
  return (
    <div className="main">
      <main>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/counsellingandpsychotherapy"
              element={<CounsellingAndPsychotherapy />}
            />
            <Route
              path="/aboutTherapist"
              element={<AboutYourTherapist />}
            />
            <Route
              path="/contactBook"
              element={<ContactBook />}
            />
            <Route
              path="/servicesCost"
              element={<ServicesCost />}
            />
            <Route
              path="/faq"
              element={<Faq />}
            />
          </Routes>
        </BrowserRouter>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
