// import "./AboutYourTherapist.css";
import "./ServicesCost.css";
import Header from "../../components/Header/Header";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { useNavigate } from "react-router-dom";

const ServicesCost = () => {
  const navigate = useNavigate();

  return (
    <div className="contactBook">
      <ScrollToTop />
      <Header source="servicesCost" />  
      <div className="aboutYourTherapist">
        <div className="online-therapy-title">Services</div>
        <div>
          <div className="services services-content">
              {/* build squares for each. big screen 3 tiles */}
              <div className="service service-1">Anxiety</div>
              <div className="service service-2">Depression</div>
              <div className="service service-3">Bereavement</div>
              <div className="service service-4">Relationship Issues</div>
              <div className="service service-5">Family Dynamics</div>
              <div className="service service-6">Self-Esteem</div>
              <div className="service service-7">Burnout</div>
              <div className="service service-8">Trauma</div>
              <div className="service service-9">Suicide/Suicidal Ideation</div>
              <div className="service service-10">Work Issues</div>
              <div className="service service-11">Loneliness</div>
              <div className="service service-12">Boundaries</div>
              <br />
              <br />
              <br />
              <br />
          </div>
            <div className="cost-content">
              <div className="online-therapy-title">Cost</div>
              <br />
              <br />
              <div className="counselling_sessions">Counselling sessions – 1 hour/65*</div>
              <br />
              <div>
                *Reduced rates are available for student,
              </div>
              <div>pensioner and unwaged clients.</div>
              <br />
              <div>Initial consultation – 15mins/Free</div>
              <br/>
              <br/>
              <div className="cost-link" onClick={() => {
                  navigate("/contactBook");
                }}>Book Now
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesCost;
