import "./CounsellingAndPsychotherapy.css";
import { useNavigate } from "react-router-dom";

const CounsellingAndPsychotherapy = () => {
  const firstLoad = localStorage.counsellingAndPsychotherapyFirstLoad;
  localStorage.counsellingAndPsychotherapyFirstLoad = 'false';
  const navigate = useNavigate();
  return (
    <section className={"online-therapy" + (firstLoad === "true" ? " online-therapy-animation" : "")}>
      <div className="online-therapy-title">
        Counselling <br /> and <br /> Psychotherapy
      </div>
      <div className="online-therapy-description">
        Offering adult <span className="online-therapy-description-number">(18+)</span> therapy
        <br/>
        face-to-face in Wexford and online
        nationwide.
        <br />
        <br />
        Confidential support to suit your needs.
        <br />
        <br />
        <a href="/contactBook" target="_blank" rel="noreferrer" onClick={(e) => {
            e.preventDefault();
            navigate("/contactBook");
          }}>Free 15-minute consultation with no obligation.</a>
      </div>
      <div className="online-therapy-description2">
        {/* All of us can find life challenging at times and therapy is there to
        provide extra support when you need it. Whether you are struggling with
        a specific issue such as anxiety, burnout, depression, suicide or
        bereavement or if you are feeling generally overwhelmed day to day,
        therapy can provide knowledge and pathways to self-empowerment and
        healing, which can help relieve these issues.
        <br />
        <br /> */}
        {/* Offering a free 15-minute, confidential consultation with no obligation. */}
      </div>
      <div
        className="online-therapy-read-more"
        onClick={() => {
          navigate("/counsellingandpsychotherapy");
        }}
      >
        Read More
      </div>
    </section>
  );
};

export default CounsellingAndPsychotherapy;
