import "./CounsellingAndPsychotherapy.css";
import Header from "../../components/Header/Header";

import { useNavigate } from "react-router-dom";

const CounsellingAndPsychotherapy = () => {
  window.scrollTo(0, 0);
  const navigate = useNavigate();

  return (
    <div className="counsellingAndPsychotherapy">
      <Header />
      <div className="online-therapy-title">
        Counselling <br /> and <br /> Psychotherapy
      </div>
      <div className="counsellingAndPsychotherapy-content">
        <div>
        All of us will find life challenging at times and counselling is there to
provide extra support when you need it. Whether you are struggling
with a specific issue such as anxiety, burnout, depression, suicide or
bereavement or if you are feeling generally overwhelmed day to day,
therapy can provide support, knowledge and pathways to self-
empowerment and healing, which can help relieve these issues.
        </div>
        <br />
        <div>
        Conversations on the importance of taking care of our mental health, in
the same way that we do with our physical health, are more frequent
today. However, I understand that seeking counselling can still feel
daunting and uncomfortable for many, especially if you don't know
what to expect.
        </div>
        <br />
        <div>
        That's why Hawthorn therapy offers a free 15-minute, non-obligatory
and confidential consultation to discuss all your queries and concerns
about therapy and help you decide how it may be of help. This can be
provided via telephone or video call. <span className="link" onClick={() => {
                  navigate("/contactBook");
                }}><b>Book Now</b></span>.
          <br/><br/>Also see the <span className="link" onClick={() => {
                  navigate("/faq");
                }}>FAQ</span> section for further information.
        </div>
        <br />
        <div>
          Hawthorn therapy is situated just outside of Wexford town on the N25
          (Eircode: <a className="link" target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/PRjfcnuDdzmiM8NPA">Y35 F593</a>) for face-to face sessions. Alternatively, sessions
          can be offered online via a secure video-call facility. Online
          sessions are open to all regardless of your location.
        </div>
      </div>
    </div>
  );
};

export default CounsellingAndPsychotherapy;
