import "./Footer.css";

import instagram from "../../images/instagram.png";
import facebook from "../../images/facebook.png";

const Footer = () => {
  return (
    <section className="footer">
      <div className="footer-socials">
        <div className="footer-socials-text">Find me</div>
        <div className="footer-instagram"><a href="https://www.instagram.com/hawthorn_therapy?igsh=MTBtb3d2NndvZHVvbg==" target="_blank" rel="noreferrer"><img width="30px" src={instagram} alt="Instagram"/></a></div>
        <div className="facebook"><a href="https://www.facebook.com/Hawthorn.Therapy.IE" target="_blank" rel="noreferrer"><img width="35px" src={facebook} alt="Facebook"/></a></div>
      </div>
      <div className="footer-copyright">
      <div>Copyright 2023.</div>
      <div>Hawthorn Therapy.</div>
      <div>All rights reserved.</div>

      </div>
    </section>
  );
};

export default Footer;
