import "./AboutYourTherapist.css";
import Header from "../../components/Header/Header";
import ruth from "../../images/ruth.jpg";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const AboutYourTherapist = () => {
  return (
    <div>
      <ScrollToTop />
      <Header />
      <div className="aboutYourTherapist">
        <div className="online-therapy-title">
          About <br /> your <br /> Therapist
        </div>
        <div className="aboutYourTherapist-photo">
          <img src={ruth} alt="Ruth - Your Therapist" />
        </div>
        <div>
          <div className="aboutYourTherapist-content">
            <div>
            My name is Ruth Lambert and I am a qualified counsellor and psychotherapist. I am a firm believer
that we should prioritise and invest in our mental health and feel honoured to work with my clients
towards that goal. For me, therapy is a collaborative process between the therapist and client and
my work is tailored to the individual needs of the client. I work by integrating various psychotherapy
theories and tools from a range of modalities such as, but not limited to, CBT, Person-Centred,
Pluralistic, Family Systems, Existential and Psychodynamic.
            </div>
            <br />
            <div>
            I strive to provide a safe, non-judgemental and confidential space, whether in-person or online, for
clients to explore the challenges they are experiencing.
            </div>
            <br />
            <div className="qualifications">Qualifications & Training</div>
            <div>
              <div>MSc in Pluralistic Counselling & Psychotherapy</div>
              <br />
              <div>BA in Integrative Counselling & Psychotherapy (Non-Award Years 1 & 2)</div>
              <br />
              <div>ASIST Suicide Intervention</div>
              <br />
              <div>Certificate in Counselling and Psychotherapeutic Studies – Special Purpose Award</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutYourTherapist;
