import "./Header.css";
import logo from "../../images/logo.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import AddTaskIcon from "@mui/icons-material/AddTask";
import PersonPinIcon from '@mui/icons-material/PersonPin';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";

import Drawer from '@mui/material/Drawer';
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useState } from "react";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

const Header = ({ source }) => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, ['top']: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem key={"Home"} disablePadding onClick={() => {
          navigate("/");
        }}>
          <ListItemButton>
            <ListItemIcon>
              <HomeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem key={"Counseling and Psychotherapy"} disablePadding onClick={() => {
          navigate("/counsellingandpsychotherapy");
        }}>
          <ListItemButton>
            <ListItemIcon>
              <InfoOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Counseling and Psychotherapy"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Contact/Book"} disablePadding onClick={() => {
          navigate("/contactBook");
        }}>
          <ListItemButton>
            <ListItemIcon>
              <EditCalendarIcon />
            </ListItemIcon>
            <ListItemText primary={"Contact/Book"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Services/Cost"} disablePadding onClick={() => {
          navigate("/servicesCost");
        }}>
          <ListItemButton>
            <ListItemIcon>
              <AddTaskIcon />
            </ListItemIcon>
            <ListItemText primary={"Services/Cost"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"About your Therapist"} disablePadding onClick={() => {
          navigate("/aboutTherapist");
        }}>
          <ListItemButton>
            <ListItemIcon>
              <PersonPinIcon />
            </ListItemIcon>
            <ListItemText primary={"About your Therapist"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"FAQ"} disablePadding onClick={() => {
          navigate("/faq");
        }}>
          <ListItemButton>
            <ListItemIcon>
              <ContactSupportIcon />
            </ListItemIcon>
            <ListItemText primary={"FAQ"} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        {['Close'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <CloseIcon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );


  return (
    <>
    <div className="menuIcon" onClick={toggleDrawer(true)}>
      <MenuRoundedIcon />
    </div>
    <section className="header">
      <Drawer
            anchor="top"
            open={state["top"]}
            onClose={toggleDrawer(false)}
          >
            {list("top")}
          </Drawer>
      <div
        className={"logo"}
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={logo} width={180} alt="logo" />
      </div>
      <div
        className={"title"}
        onClick={() => {
          navigate("/");
        }}
      >
        Hawthorn<br></br> Therapy
      </div>
      {source !== "ContactBook" && (
        <>
          <div
            className={"address"}
          >
            <a href="https://maps.app.goo.gl/PRjfcnuDdzmiM8NPA" target="_blank" rel="noreferrer">
            Barntown,
            <br />
            Co. Wexford,
            <br />
            Y35 F593
            </a>
          </div>
          <div className="test">
              <div className="emailText">
                <a href="mailto:ruth@hawthorntherapy.ie">
                  ruth@hawthorntherapy.ie
                </a>
              </div>
              <div className="phoneText">
              <a href="https://wa.me/+353830929288" target="_blank" rel="noreferrer"><WhatsAppIcon /> 083 0929288</a>
              </div>
          </div>
        </>
      )}
    </section>
    </>
  );
};

export default Header;
