import Testimonials from "../../components/Testimonials/Testimonials";
import CounsellingAndPsychotherapy from "../../components/CounsellingAndPsychotherapy/CounsellingAndPsychotherapy";
import Faq from "../../components/Faq/Faq";
import Header from "../../components/Header/Header";
// import Header from "../../components/Header/Header";

const Home = () => {
  return (
    <>
      <Header />
      <CounsellingAndPsychotherapy />
      <Testimonials />
    </>
  );
};

export default Home;
