import "./Faq.css";
import Header from "../../components/Header/Header";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import QuizIcon from '@mui/icons-material/Quiz';
import FaqComp from "../../components/Faq/Faq";

const Faq = () => {
  return (
    // <section className="faq">
    <div className="contactBook">
      <ScrollToTop />
      <Header source="faq" />
      <div className="aboutYourTherapist">
        {/* <div className="faq-icon"><QuizIcon fontSize="large"/></div>
        <div className="faq-title">
            Frequently <br /> Asked <br /> Questions
        </div> */}
        <FaqComp/>
      </div>
      </div>
    // </section>
  );
}

export default Faq;