import "./ContactBook.css";
import Header from "../../components/Header/Header";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import axios from "axios";
import instagram from "../../images/instagram.png";
import facebook from "../../images/facebook.png";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from "react";
import { Button } from "@mui/material";

const ContactBook = () => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState({title: 'Thank you', content: 'Form submitted.'});
  const [scroll, setScroll] = useState(true);
  

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const sendform = (event) => {
    event.preventDefault();

    if (event.target[4].value.trim() === '') {
      setDialogText({title: 'Attention', content: 'Please enter your name.'});
      setDialogOpen(true);
      return;
    }

    if (event.target[5].value.trim() === '' && event.target[6].value.trim() === '') {
      setDialogText({title: 'Attention', content: 'Please enter your email or phone number.'});
      setDialogOpen(true);
      return;
    }

    if (event.target[7].value.trim() === '') {
      setDialogText({title: 'Attention', content: 'Please leave a message.'});
      setDialogOpen(true);
      return;
    }

    setOpen(true);
    let option = '';
    if (event.target[1].checked) {
      option = event.target[1].value;
    } else if (event.target[2].checked) {
      option = event.target[2].value;
    } else if (event.target[2].checked) {
      option = event.target[3].value;
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios
      .post("https://formsubmit.co/ajax/ruth@hawthorntherapy.ie", {
        reason: option,
        name: event.target[4].value,
        email: event.target[5].value,
        phone: event.target[6].value,
        message: event.target[7].value,
      })
      .then((response) => { 
        event.target.reset();
        setDialogText({title: 'Thank you for your query', content: 'Please allow up to 2 business days for a response.'});
        setOpen(false);
        setDialogOpen(true);
      })
      .catch((error) => {
        setOpen(false);
        setDialogText({title: 'Sorry', content: 'Failed to send contact details. Please try again.'});
        setDialogOpen(true);
      });
  };

  return (
    <div className="contactBook">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={dialogOpen}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>{dialogText.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {dialogText.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" sx={{
            color: 'white',
            fontSize:'18px',
            padding: '5px 20px',
            backgroundColor: '#2f7d52',
            '&:hover': {
              background:'#2f7d52'
         },
          }} onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <ScrollToTop scroll={scroll} setScroll={setScroll}/>
      <Header source="ContactBook" />
      <div className="aboutYourTherapist">
        <div className="contact-book-title">Contact/Book</div>
        <div className="contact-book-sub-text">You can contact me directly at the email or phone number provided. If you prefer you can fill in the contact form below.</div>
      </div>
      <div className="contactForm">
        <div className="container">
          <div className="content">
            <div className="left-side">
              <div className="contact-address details">
                <i>
                  <LocationOnOutlinedIcon />
                </i>
                {/* <div className="topic">Address</div> */}
                <div className="text-one">Barntown,</div>
                <div className="text-one">Co. Wexford,</div>
                <div className="text-two">Y35 F593</div>
              </div>
              <div className="contact-phone details">
                <i>
                  <WhatsAppIcon />
                </i>
                {/* <div className="topic">Phone</div> */}
                <div className="text-one">+353 83 092 9288</div>
              </div>
              <div className="contact-email details">
                <i>
                  <EmailOutlinedIcon />
                </i>
                {/* <div className="topic">Email</div> */}
                <div className="text-one">
                  <a
                    className="contactEmailText"
                    href="mailto:ruth@hawthorntherapy.ie"
                  >
                    ruth@hawthorntherapy.ie
                  </a>
                </div>
              </div>
              <div className="contact-address details">
                <div className="text-one">Socials</div>
                <div className="text-one">
                  <a href="https://www.instagram.com/hawthorn_therapy?igsh=MTBtb3d2NndvZHVvbg==" target="_blank" rel="noreferrer">
                    <img width="25px" src={instagram} alt="Instagram" />
                  </a>
                </div>
                <div className="text-one">
                <a href="https://www.facebook.com/Hawthorn.Therapy.IE" target="_blank" rel="noreferrer">
                  <img width="25px" src={facebook} alt="Facebook" />
                  </a>
                </div>
              </div>
            </div>
            <div className="right-side">
              <div className="topic-text">Contact</div>
              {/* <p>
                    If you have any work from me or any types of quries related
                    to my tutorial, you can send me message from here. It's my
                    pleasure to help you.
                  </p> */}
              <form onSubmit={sendform}>
                <input
                  type="hidden"
                  name="_next"
                  value="http://localhost:3000/contactBook?form=submitted"
                />
                <div className="formOptions">
                  <br/>
                  <input type="radio" defaultChecked id="free_consultation" name="form_options" value="Free Consultation" />
                  <label htmlFor="free_consultation">Free Consultation</label><br/>
                  <input type="radio" id="appointment_booking" name="form_options" value="Appointment Booking"/>
                  <label htmlFor="appointment_booking">Appointment Booking</label><br/>
                  <input type="radio" id="general_queries" name="form_options" value="General Queries"/>
                  <label htmlFor="general_queries">General Queries</label>
                </div>
                <div className="input-box">
                  <input type="text" placeholder="Enter your name" />
                </div>
                <div className="input-box">
                  <input type="text" placeholder="Enter your email" />
                </div>
                <div className="input-box">
                  <input type="text" placeholder="Enter your phone number" />
                </div>
                <div className="input-box message-box">
                  <textarea placeholder="Enter your message"></textarea>
                </div>
                <div className="button">
                  <input type="submit" value="Send Now" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBook;
